<template>
  <div class='sub-page sub-page-leftmenu'>
    <div class='sub-tab-wrap'>
      <DxTabPanel
          id="tabpanel"
          :selected-index.sync="selectedIndex"
          :data-source='tabs'
          :focusStateEnabled='true'
        >
        <template #title="{ data: tab }">
        <div class='sub-title-wrap-tab'>
          <ul>
            <li class='float-left sub-title'>{{tab.title}}</li>
          </ul>
          </div>
        </template>
      </DxTabPanel>
    </div>

    <div class='sub-content'> <!-- 여기까지 page header 이하 수정해서 사용-->
      <div class='grid-wrap'>
        <dx-data-grid ref='refInventoryGrid'
          :data-source='inventoryReceiptInfo'
          :show-borders = 'true' :showRowLines = 'false' :showColumnLines='true'
          :hover-state-enabled = "true" :allowColumnResizing = 'true'
          column-resizing-mode = 'widget' :allow-column-reordering = "true"
          :height='gridHeight' :row-alternation-enabled="true"
          :focused-row-enabled="true"
          @focused-row-changed="onSelectionChanged"
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
          @toolbar-preparing='onToolbarPreparing'
          @cell-prepared='onCellPreparedOutput'
        >
          <DxSearchPanel
            :visible="true"
            placeholder="검색..."
          />
          <DxExport
            :enabled="true"
            :file-name="exportTitle"
          />
          <DxFilterRow
            :visible="true"
            apply-filter="auto"
          />
          <DxColumnFixing :enabled="false"/>
          <DxColumn
            :allowEditing='false'
            :fixed="false"
            caption="발생일"
            data-field="발생일자"
            dataType='datetime'
            format='yyyy-MM-dd'>
          </DxColumn>
          <DxColumn v-if="$store.getters.getGroupName !== null"
            :allowEditing='true'
            :fixed="false"
            :caption="$store.getters.getGroupName"
            data-field="차종"
            :visible='true'
          >
          </DxColumn>
          <DxColumn
            :allowEditing='true'
            :fixed="false"
            caption="품번"
            data-field="품번"
          >
          </DxColumn>
          <DxColumn
            :allowEditing='true'
            :fixed="false"
            caption="품명"
            data-field="품명"
            :visible='true'
          >
          </DxColumn>
          <DxColumn
          :allowEditing='false'
          caption="자재유형"
          data-field="자재유형"
        >
        <DxLookup
            :data-source="meterialTypeLookup"
            display-expr="코드명"
            value-expr="코드"
          />
        </DxColumn>
          <DxColumn
            :allowEditing='true'
            caption="수량"
            data-field="수량"
            :visible='true'
            format='#,##0'
            dataType="number"
          >
          </DxColumn>
           <DxColumn
            :allowEditing='true'
            caption="판매금액"
            data-field="판매금액"
            :visible='true'
            format='#,##0'
            dataType="number"
          >
          </DxColumn>
          <DxSummary>
            <DxTotalItem
              value-format='#,##0'
              column="발생일자"
              display-format="{0} (건)"
            />
            <DxTotalItem
              value-format='#,##0'
              summary-type="sum"
              column="수량"
              display-format="{0}"
            />
            <DxTotalItem
              value-format='#,##0'
              summary-type="sum"
              column="판매금액"
              display-format="{0} (원)"
            />
          </DxSummary>
          <dx-paging :enabled="false"/>
          <dx-scrolling mode="virtual" showScrollbar='always' :useNative='false'/>
          <dx-sorting mode="multiple"/>
        </dx-data-grid>
      </div>
      <loading :active.sync="isLoading" color='rgb(2, 110, 156)' loader='bars'></loading>
    </div> <!--sub-conten end-->

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import DxTabPanel from 'devextreme-vue/tab-panel'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import AppLib from '../../../../MesSmartVue/src/share/AppLib'
import DxDataGrid, {
  DxScrolling, DxFilterRow, DxColumnFixing, DxTotalItem, DxLookup,
  DxSummary, DxColumn, DxPaging, DxSorting, DxSearchPanel, DxExport
} from 'devextreme-vue/data-grid'

export default {
  name: 'InputList',
  data: function () {
    return {
      isLoading: false,
      selectedIndex: 0,
      vendorInfo: null,
      gridHeight: null,
      tabs: [
        {
          title: '납품내역'
        }
      ],
      inventoryReceiptInfo: null,
      selectedRow: null,
      thisYear: null,
      yearArray: [],
      thisMonth: null,
      monthArray: [],
      meterialTypeLookup: null,
      exportTitle: null,
      receiptTypeLookup: null,
      receiptType: null,
      outCaption: null,
      inCaption: null
    }
  },
  components: {
    Loading,
    DxTabPanel,
    DxDataGrid,
    DxScrolling,
    DxFilterRow,
    DxColumnFixing,
    DxTotalItem,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxExport,
    DxLookup
  },
  created () {
    document.title = ConstDef.TAB_TITLE
    this.vendorInfo = this.$store.getters.getVendorInfo
    if (this.vendorInfo === undefined || this.vendorInfo === null) {
      this.$router.push('/')
      return
    }
    this.thisYear = AppLib.getThisYear()
    for (let i = this.thisYear; i >= 2021; i--) {
      this.yearArray.push({ year: i })
    }
    this.thisMonth = AppLib.getThisMonth()
    for (let i = 1; i <= 12; i++) {
      if (i > 0 && i < 10) {
        this.monthArray.push({ name: i + '월', value: '0' + i })
      } else {
        this.monthArray.push({ name: i + '월', value: '' + i })
      }
    }
    this.meterialTypeLookup = this.$store.getters.getBaseDataByType(ConstDef.자재유형)
    this.refreshInventoryList(this.vendorInfo.거래처코드)
  },
  mounted () {
    this.$Q('.sub-tab-wrap').css('width', (window.innerWidth - ConstDef.LEFT_MENU_WIDTH - 40) + 'px')
    this.gridHeight = (screen.height - 250) * 0.95
  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    onCellPreparedOutput (e) {
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
      }
    },
    onToolbarPreparing (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: function () {
            return '<div class="toolbar-label" style="margin-left:4px;color:rgb(4, 103, 189);"><b>연도 :  </b></div>'
          }
        },
        {
          location: 'before',
          widget: 'dxSelectBox',
          options: {
            width: 80,
            items: this.yearArray,
            valueExpr: 'year',
            displayExpr: 'year',
            value: this.thisYear,
            onValueChanged: (args) => {
              this.thisYear = args.value
              this.refreshInventoryList(this.vendorInfo.거래처코드)
            }
          }
        },
        {
          location: 'before',
          template: function () {
            return '<div class="toolbar-label" style="margin-left:4px;color:rgb(4, 103, 189);"><b>월 :  </b></div>'
          }
        },
        {
          location: 'before',
          widget: 'dxSelectBox',
          options: {
            width: 80,
            items: this.monthArray,
            valueExpr: 'value',
            displayExpr: 'name',
            value: this.thisMonth,
            onValueChanged: (args) => {
              this.thisMonth = args.value
              this.refreshInventoryList(this.vendorInfo.거래처코드)
            }
          }
        }
      )
    },
    refreshInventoryList (거래처코드) {
      this.exportTitle = `${this.thisYear}-${this.thisMonth}-자재입출고현황`
      this.tabs = [
        {
          title: '자재 입고내역서'
        }
      ]
      this.outCaption = '출고(반)'
      this.inCaption = '입고업체'

      this.isLoading = true
      const param = {
        startDate: `${this.thisYear}-${this.thisMonth}-01`,
        endDate: `${this.thisYear}-${this.thisMonth}-${AppLib.getMonthLastDay(this.thisYear, this.thisMonth)} 23:59:59`,
        거래처코드: 거래처코드
      }
      this.$_sp.runNoEncodeFindProc('spFindAllB2bInventoryReceiptByDateAndVendorCode', param)
        .then((data) => {
          this.isLoading = false
          this.inventoryReceiptInfo = this.$_sp.MakeModel(data)
          console.log(this.inventoryReceiptInfo)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
